<template>
  <div class="box">
    <el-form :model="info" label-position="left" :rules="rules" ref="ruleForm" label-width="80px" size="small">
<!--      <el-form-item label="选择门店" prop="store_id" v-if="shopList && shopList.length>0">-->
<!--        <el-select v-model="info.store_id" placeholder="请选择门店" size="small" style="width: 100%">-->
<!--          <el-option-->
<!--              v-for="item in shopList"-->
<!--              :key="item.id"-->
<!--              :label="item.title"-->
<!--              :value="item.id">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item label="会议周期" prop="day" v-if="info.type==3">
        <el-date-picker
            v-model="info.day"
            type="week"
            disabled
            :picker-options="{firstDayOfWeek:1}"
            format="yyyy 第 WW 周"
            placeholder="选择周">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="会议日期" prop="day" v-if="info.type==1 || info.type==2">
        <el-date-picker
            v-model="info.day"
            type="date"
            disabled
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="会议月份" prop="month" v-if="info.type==4">
        <el-date-picker
            v-model="info.day"
            type="month"
            disabled
            placeholder="选择月">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="开会时间">
        <el-date-picker class="mg-rt-10"
            v-model="info.meeting_date"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
        <el-time-select
            v-model="info.meeting_time"
            placeholder="选择时间">
        </el-time-select>
      </el-form-item>
      <el-form-item label="记录人" prop="note_taker">
        <el-input v-model="info.note_taker" placeholder="请输入记录人"></el-input>
      </el-form-item>
      <el-form-item label="会议主题" prop="theme">
        <el-input type="textarea" rows="4" v-model="info.theme" placeholder="请输入主题"></el-input>
      </el-form-item>
      <el-form-item label="参会人" prop="people">
        <el-select v-model="info.people" multiple placeholder="请选择" style="width: 100%">
          <el-option
              v-for="item in info.staffList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">确定会议</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import util from "@/utils/util";
export default {
  data() {
    return {
      info: {
        store_id: '',
        type: '',
        day: '',
        meeting_date: '',
        meeting_time: '',
        note_taker: '',
        theme: '',
        people: '',
        staffList: []
      },
      rules: {
        // week: [
        //   {required: true, message: '请输入周期', trigger: 'blur'}
        // ],
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    weekInfo: Object
  },
  watch: {
    weekInfo: {
      handler: function(newVal, oldV) {
        this.info = newVal
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    submitForm(formName) {
      var _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var param = _this.info
          // var week = new Date(util.formatDate(this.week))
          // week.setDate(week.getDate() - 1)
          // param.week = util.formatDate(week)
          // if(param.meeting_date) {
          //   param.meeting_date = util.formatDate(param.meeting_date)
          // }
          this.$api.operate.weekAdd(param,function (res) {
            if(res.errcode == 0) {
              // _this.success('保存成功')
              _this.$router.push({name: 'operate_week_show', query: {id: res.data}})
              // _this.$emit('callFunc');
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
